import {
  OfficeName,
  PracticeQueryParams,
} from 'constants/application-constants'

import { useEffect, useState } from 'react'

import { MoreVert, Repeat } from 'assets/Icons'
import {
  ButtonPopover,
  DropdownItem,
  LoadingIndicator,
  Typography,
} from 'components/common'
import config from 'config'
import { t } from 'i18next'
import { Download, Edit2, Link as LinkIcon } from 'react-feather'
import { Link, useSearchParams } from 'react-router-dom'

import styles from './DocumentMorePopover.module.scss'
import { copyToClipboard } from '../../../../../helpers'
import { useAppDispatch, useFrontOfficeCheck } from '../../../../../hooks'
import { addToast } from '../../../Other/api'
import { GetDownloadUrlRequest } from '../../api/documentApi.types'
import { documentApi } from '../../api/documentsApi'
import DocRevisionEnum from '../../enums/DocRevision'

interface DocumentMorePopoverProps {
  documentId: string
  documentType?: string
  className?: string
}

const DocumentMorePopover = ({
  documentId,
  documentType = 'annex',
  className = '',
}: DocumentMorePopoverProps) => {
  const dispatch = useAppDispatch()
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null)
  const [searchParams] = useSearchParams()
  const isFrontOffice = useFrontOfficeCheck()
  const [triggerGetDownloadUrl] =
    documentApi.useLazyGetDocumentFileDownloadEntityByIdQuery()

  const togglePopover = () => {
    setPopoverOpen((prev) => !prev)
  }

  const isDownloadDisabled = !(downloadUrl !== null && downloadUrl.length > 0)

  const revisionStatus = searchParams.get(PracticeQueryParams.REVISION_STATUS)
  const querySearch = `rs=${
    revisionStatus ? revisionStatus : DocRevisionEnum.published
  }`

  const isDraft = revisionStatus === DocRevisionEnum.draft

  /**
   * Refresh every time the popover is opened to get the newest signed URL
   */
  useEffect(() => {
    setDownloadUrl(null)

    const props: GetDownloadUrlRequest = {
      id: documentId,
      rs:
        !isFrontOffice && revisionStatus
          ? (revisionStatus as DocRevisionEnum)
          : undefined,
    }

    if (
      isFrontOffice &&
      documentType !== 'template' &&
      documentType !== 'checklist'
    ) {
      props.format = 'pdf'
    }

    if (popoverOpen === true) {
      triggerGetDownloadUrl(props, false).then((result) => {
        setDownloadUrl(result.data?.data.file.url ?? null)
      })
    }
  }, [
    popoverOpen,
    documentId,
    documentType,
    triggerGetDownloadUrl,
    isFrontOffice,
    revisionStatus,
  ])

  const handleCopyClick = () => {
    copyToClipboard(
      `${config.BASE_URL}/${
        isFrontOffice ? OfficeName.FRONT : OfficeName.BACK
      }/documents/${documentId}${isFrontOffice ? '' : window.location.search}`
    )
    dispatch(addToast({ color: 'success', content: t('document.linkCopy') }))
  }

  return (
    <ButtonPopover
      id={`view-document-${documentId}`}
      buttonProps={{
        color: 'default',
        size: 'x-small',
        variant: 'subtle',
        icon: <MoreVert />,
        onClick: togglePopover,
      }}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      open={popoverOpen}
      updateOpen={togglePopover}
      rootClassName={className}
      buttonLabel={`${t('document.options')}`}
    >
      {!isFrontOffice && isDraft && (
        <>
          <Link
            className={styles.link}
            onClick={togglePopover}
            to={{
              pathname: `/${OfficeName.BACK}/documents/${documentId}/replace`,
              search: querySearch,
            }}
          >
            <DropdownItem icon={<Repeat />}>
              {/* this is a temporary translation, should be "Replace document" / "Dokument ersetzen" */}
              <Typography>{t('document.popoverReplace')}</Typography>
            </DropdownItem>
          </Link>
          <Link
            className={styles.link}
            onClick={togglePopover}
            to={{
              pathname: `/${OfficeName.BACK}/documents/${documentId}/edit`,
              search: querySearch,
            }}
          >
            <DropdownItem icon={<Edit2 />}>
              <Typography>{t('document.popoverEdit')}</Typography>
            </DropdownItem>
          </Link>
        </>
      )}
      <a
        href={downloadUrl ?? ''}
        target="_blank"
        onClick={togglePopover}
        rel="noreferrer"
        className={styles.link}
      >
        <DropdownItem
          icon={
            isDownloadDisabled ? (
              <LoadingIndicator size="x-small" />
            ) : (
              <Download />
            )
          }
          disabled={isDownloadDisabled}
        >
          <Typography>{t('document.popoverDownload')}</Typography>
        </DropdownItem>
      </a>
      <DropdownItem icon={<LinkIcon />} onClick={handleCopyClick}>
        <Typography>{t('document.copyLink')}</Typography>
      </DropdownItem>
    </ButtonPopover>
  )
}

export default DocumentMorePopover
