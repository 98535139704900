import { useEffect, useState } from 'react'

import { Download } from 'react-feather'

import styles from './DocumentDownloadUrl.module.scss'
import { Button, LoadingIndicator, Typography } from '../../../../common'
import { ButtonVariants } from '../../../../common/Button/Button.types'
import { GetDownloadUrlRequest } from '../../api/documentApi.types'
import { documentApi } from '../../api/documentsApi'

interface DocumentDownloadUrlProps {
  documentId: string
  documentType?: string
  button?: {
    variant?: ButtonVariants
    label?: string
  }
}

const DocumentDownloadUrl = ({
  documentId,
  documentType = 'annex',
  button = { variant: 'subtle' },
}: DocumentDownloadUrlProps) => {
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null)
  const [triggerGetDownloadUrl] =
    documentApi.useLazyGetDocumentFileDownloadEntityByIdQuery()

  const isDownloadDisabled = !(downloadUrl !== null && downloadUrl.length > 0)

  const onDownloadClick = () => {
    if (!downloadUrl) return false
    window.open(downloadUrl ?? '', '_blank')

    const props: GetDownloadUrlRequest = { id: documentId }
    if (documentType !== 'template' && documentType !== 'checklist')
      props.format = 'pdf'

    triggerGetDownloadUrl(props, false).then((result) => {
      setDownloadUrl(result.data?.data.file.url ?? null)
    })
  }

  useEffect(() => {
    setDownloadUrl(null)

    const props: GetDownloadUrlRequest = { id: documentId }
    if (documentType !== 'template' && documentType !== 'checklist')
      props.format = 'pdf'

    triggerGetDownloadUrl(props, false).then((result) => {
      setDownloadUrl(result.data?.data.file.url ?? null)
    })

    return () => {
      setDownloadUrl(null)
    }
  }, [documentId, documentType, triggerGetDownloadUrl])

  return (
    <Button
      icon={
        isDownloadDisabled ? <LoadingIndicator size="x-small" /> : <Download />
      }
      className={button?.variant === 'subtle' ? styles.fakeButton : ''}
      disabled={isDownloadDisabled}
      variant={button?.variant}
      onClick={onDownloadClick}
      size="x-small"
    >
      {button?.label && <Typography>{button.label}</Typography>}
    </Button>
  )
}

export default DocumentDownloadUrl
